import {Button, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {Redirect, Route, Switch, useHistory, useParams} from 'react-router';
import {getGallery} from './api/gallery';
import {LoadingIndicator} from './components/LoadingIndicator';
import {NameDialog} from './components/NameDialog';
import {useUser} from './context/user';
import {DefaultLayout} from './layouts/default';
import {EnterCode} from './pages/EnterCode';
import {OwnPhotos} from './pages/OwnPhotos';
import {PhotoGallery} from './pages/PhotoGallery';
import {PhotoView} from './pages/PhotoView/PhotoView';
import {getCode, setFavicon} from './util';
import {useUiStore} from "./context/ui";

export const Router = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Check if there was a previous code stored
    if (window.location.pathname === '/' || !window.location.pathname) {
      const lastCode = window.localStorage.getItem('photoShareApp:lastCode');
      if (lastCode) {
        history.push(`/${lastCode}`);
      }
    }
    setLoaded(true);
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <Switch>
      <Route path={['/:code(\\d{8})', '/:code(\\d{4}-\\d{4})']}>
        <PhotoGalleryRouter />
      </Route>
      <Route path={'/'}>
        <EnterCode />
      </Route>
    </Switch>
  );
};

export const PhotoGalleryRouter = observer(() => {
  const user = useUser();
  const params = useParams<{code: string}>();
  const code = getCode(params.code);
  const ui = useUiStore();
  const {data, error, refetch, isFetching, status} = useQuery(`/gallery/${code}`, () => getGallery(code), {
    staleTime: 1500000,
    refetchInterval: 1800000 + Math.random() * 300000, // Every 30 - 35 minutes
  });
  const {allowDownloads, allowSharing, allowComments} = data || {};

  useEffect(() => {
    ui.setAllowDownloads(!!allowDownloads);
    ui.setAllowSharing(!!allowSharing);
    ui.setAllowComments(!!allowComments);
  }, [allowDownloads, allowSharing, allowComments]);

  useEffect(() => {
    if (data) {
      window.localStorage.setItem('photoShareApp:lastCode', code);
      document.title = data?.name || 'Snappy - Photo Sharing App';
      /* If we have a favicon? */
      if (data?.logo) {
        setFavicon(data.logo);
      }

      if (data?.customStylesheetUrl) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = data.customStylesheetUrl;
        link.setAttribute('data-custom', 'true');
        document.head.appendChild(link);
      }
    } else {
      window.localStorage.removeItem('photoShareApp:lastCode');
      // Remove any <link> elements that have data-custom="true"
      const links = document.querySelectorAll('link[data-custom="true"]');
      links.forEach((link) => {
        link.remove();
      });
    }
    return () => {
      const links = document.querySelectorAll('link[data-custom="true"]');
      links.forEach((link) => {
        link.remove();
      });
    }
  }, [data?.id, code]);

  if (error && (error as Error).message === 'not-found') {
    return <Redirect to={'/'} />;
  } else if (error && !isFetching) {
    return (
      <div
        style={{
          height: '100vh',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}>
        <div style={{textAlign: 'center'}}>
          <Typography align={'center'}>Error Loading Gallery</Typography>
          <Button onClick={() => refetch()}>Try Again</Button>
        </div>
      </div>
    );
  }

  return (
    <DefaultLayout name={data?.name || 'Snappy'} logo={data?.logo}>
      {/* TODO: Find out why this disappears if you go straight to an image. Is user.name null maybe? */}
      <NameDialog show={user.name === '' || user.name === null} />
      {!data ? (
        <LoadingIndicator />
      ) : (
        <>
          <Route path={'/:code/photo/:photoId'} exact={true}>
            <PhotoView galleryId={data?.id} />
          </Route>
          <Switch>
            <Route path={'/:code/my'} exact={true}>
              <OwnPhotos galleryId={data?.id} />
            </Route>
            <Route path={'/:code/tag/:tag'} exact={true}>
              <PhotoGallery galleryId={data?.id} key={'tagged'} hideUploads={!!data?.hideUploads} />
            </Route>
            <Route path={''}>
              <PhotoGallery galleryId={data?.id} key={'default'} hideUploads={!!data?.hideUploads} />
            </Route>
          </Switch>
        </>
      )}
    </DefaultLayout>
  );
});
