import {Container, Toolbar} from '@material-ui/core';
import {PropsWithChildren} from 'react';
import {Header} from '../components/Header';
import {portal} from './portal';

export const DefaultLayout = (props: PropsWithChildren<{name: string, logo?: string}>) => {
  return (
    <>
      <Header name={props.name} logo={props.logo} />
      <Toolbar />
      <Container maxWidth={'md'} component={'main'}>
        <>{props.children}</>
      </Container>
      <div ref={portal} />
    </>
  );
};
