import {action, makeObservable, observable} from 'mobx';

export enum SortMethod {
  MOST_RECENT = 0,
  MOST_LIKES = 1,
}

class UiStore {
  sortMethod: SortMethod = SortMethod.MOST_RECENT;
  allowDownloads: boolean = false;
  allowComments: boolean = false;
  allowSharing: boolean = false;

  constructor() {
    makeObservable(this, {
      sortMethod: observable,
      setSortMethod: action,
      allowDownloads: observable,
      setAllowDownloads: action,
      allowComments: observable,
      setAllowComments: action,
      allowSharing: observable,
      setAllowSharing: action,
    });
  }

  setSortMethod = (method: SortMethod) => {
    this.sortMethod = method;
  };

  setAllowDownloads = (allow: boolean) => {
    this.allowDownloads = allow;
  }

  setAllowComments = (allow: boolean) => {
    this.allowComments = allow;
  }

  setAllowSharing = (allow: boolean) => {
    this.allowSharing = allow;
  }
}


export const uiStore = new UiStore();
